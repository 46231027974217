<script setup>
const emit = defineEmits(['update:checked']);

const props = defineProps({
    checked: {
        type: [Array, Boolean],
        default: false,
    },
    label: {
        type: String,
        default: null,
    }
});
const attrs = useAttrs()
const proxyChecked = computed({
    get() {
        return props.checked;
    },
    set(val) {
        emit('update:checked', val);
    },
});
</script>

<template>
    <label :class="$attrs?.disabled ? 'opacity-50' : 'cursor-pointer'" class="flex items-center gap-2 disabled:opacity-50 grow flex-none whitespace-nowrap text-medium">
        <input
            v-model="proxyChecked"
            type="checkbox"
            v-bind="$attrs"
            class="rounded border-tertiary bg-overlay-6 text-primary checked:bg-primary-inverted focus:text-primary focus:ring-primary size-6 transition-colors cursor-pointer indeterminate:bg-primary-inverted indeterminate:border-tertiary "
        >
        <span v-if="label">{{label}}</span>
        <span v-else><slot /></span>
    </label>
</template>
